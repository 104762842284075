import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/react";
import { BackTop, Button, Layout } from "antd";
import { initializeApp } from "firebase/app";
import { Auth, getAuth, onAuthStateChanged } from "firebase/auth";
import moment from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSearchParams } from "react-router-dom";
import "./App.css";
import EmailUdpateModal from "./Components/EmailUpdate";
import FooterCopyright from "./Components/FooterCopyright";
import LoaderComponent from "./Components/LoaderComponent";
import MainHeader from "./Components/MainHeader";
import RouterComponent from "./Components/RouterComponent";
import SearchBoxFilter from "./Components/SearchBoxFilter";
import SearchBoxWidget from "./Components/SearchBoxFilter/SearchBoxWidget";
import { SearchContext } from "./Context/context";
import { PriceContext } from "./Context/RoomContext";
import { useViewport, ViewportProvider } from "./Context/useViewport";
import { WebConfigContext } from "./Context/WebConfigContext";
import "./dashboard.css";
import { isMembershipApproved } from "./Data/MembershiStatusData";
import { getMembershipStatus } from "./helpers/requestMembership";
import { getByName, getWebConfig } from "./helpers/requestSetting";
import { getUserRol } from "./helpers/requestUserAndRole";
import { IPriceResponse } from "./Models/Checkout";
import { ISearch, objectContext } from "./Models/Search";
import { IWebConfig, IWebConfigContext } from "./Models/SettingsModel";
import { IAccess } from "./Models/UsersModel";

import {
  addClickTripz,
  addGtag739,
  removeClickTripz,
} from "./helpers/addRemoveClickTripz";
import { getIp } from "./helpers/configHelper";

const { Footer, Content } = Layout;

const App = (): ReactElement => {
  const [searchParams] = useSearchParams();
  const { height } = useViewport();
  const [modalVisible, setModalVisible] = useState(false);
  const [config, setConfig] = useState<boolean>(false);
  const [authF, setAuthF] = useState<Auth>();
  const [isSearchBoxVisible, setisSearchBoxVisible] = useState(true);

  const checkin =
    searchParams.get("checkin") === "null" ||
    searchParams.get("checkin") === null ||
    searchParams.get("checkin") === undefined
      ? moment().add(1, "days").format("YYYY-MM-DD")
      : searchParams.get("checkin");
  const checkout =
    searchParams.get("checkout") === "null" ||
    searchParams.get("checkout") === null ||
    searchParams.get("checkout") === undefined
      ? moment().add(2, "days").format("YYYY-MM-DD")
      : searchParams.get("checkout");
  const currencyParams = searchParams.get("currency");

  const [priceContext, setPriceContext] = useState<
    React.Context<{} | IPriceResponse> | IPriceResponse | {}
  >({});
  const [webConfigContext, setWebConfigContext] = useState<
    React.Context<{} | IWebConfigContext> | IWebConfigContext | {}
  >({});
  const [searchContent, setSearchContent] = useState<
    React.Context<{} | ISearch> | ISearch
  >(
    objectContext(
      checkin,
      checkout,
      [
        {
          numOfAdults: 2,
          childAges: [],
        },
      ],
      currencyParams
    )
  );
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [accessRoles, setAccessRoles] = useState<IAccess[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isMember, setisMember] = useState(false);

  const handlerFilterClick = () => {
    setMenuOpen(!menuOpen);
  };

  function isNoIframeOrIframeInMyHost() {
    // Validation: it must be loaded as the top page, or if it is loaded in an iframe
    // then it must be embedded in my own domain.
    // Info: IF top.location.href is not accessible THEN it is embedded in an iframe
    // and the domains are different.
    let myresult = true;
    try {
      const tophref = top?.location.href;
      const tophostname = top?.location.hostname.toString();
      const myhref = location.href;
      if (tophref === myhref) {
        myresult = true;
      } else if (tophostname !== "app.dealpeak.com") {
        myresult = false;
      }
    } catch (error) {
      // error is a permission error that top.location.href is not accessible
      // (which means parent domain <> iframe domain)!
      myresult = false;
    }
    return myresult;
  }

  const setOnLocalChannel = (
    channel: string,
    webConfigResp: IWebConfig,
    mobile: boolean
  ) => {
    setWebConfigContext({
      webConfig: webConfigResp,
      selectedChannel: channel,
      mobile,
    });
    setLoading(false);
  };

  const setChannelId = async (roles: string, webConfigResp: IWebConfig) => {
    switch (roles) {
      case "SalesPerson":
        localStorage.setItem("UserAuth", "true");
        setOnLocalChannel(
          webConfigResp.REACT_APP_CHANNEL_ID.Sales,
          webConfigResp,
          true
        );
        return;
      case "MembershipDP":
        localStorage.setItem("UserAuth", "true");
        setOnLocalChannel(
          webConfigResp.REACT_APP_CHANNEL_ID.MembershipDP,
          webConfigResp,
          true
        );
        return;
      case "GoogleMeta":
        setOnLocalChannel(
          webConfigResp.REACT_APP_CHANNEL_ID.GoogleMeta,
          webConfigResp,
          isMobile
        );
        return;
      case "Admin":
        setOnLocalChannel(
          webConfigResp.REACT_APP_CHANNEL_ID.MembershipDP,
          webConfigResp,
          isMobile
        );
        return;
      default:
        setOnLocalChannel(
          isMobile
            ? webConfigResp.REACT_APP_CHANNEL_ID.Mobile
            : webConfigResp.REACT_APP_CHANNEL_ID.Organic,
          webConfigResp,
          isMobile
        );
    }
  };

  const getAuthTest = async (authFirebase: Auth) => {
    const webConfigResp = await getWebConfig();
    onAuthStateChanged(authFirebase, async (user) => {
      if (user) {
        if (user.email) {
          try {
            const membershipResponse = await getMembershipStatus(user.email);
            const response = await getUserRol(user.email);
            if (
              membershipResponse &&
              isMembershipApproved(membershipResponse[0].orderStatus)
            ) {
              setChannelId("MembershipDP", webConfigResp);
            } else {
              setChannelId(response.role.roleName, webConfigResp);
            }
            setAccessRoles(response.role.access);
          } catch (err) {
            if (webConfigResp)
              if (isMobile) {
                setOnLocalChannel(
                  webConfigResp.REACT_APP_CHANNEL_ID.Mobile,
                  webConfigResp,
                  isMobile
                );
              } else {
                setOnLocalChannel(
                  webConfigResp.REACT_APP_CHANNEL_ID.Organic,
                  webConfigResp,
                  isMobile
                );
              }
          }
        } else {
          setModalVisible(true);
        }
      } else if (webConfigResp)
        if (isMobile) {
          setOnLocalChannel(
            webConfigResp.REACT_APP_CHANNEL_ID.Mobile,
            webConfigResp,
            isMobile
          );
        } else {
          setOnLocalChannel(
            webConfigResp.REACT_APP_CHANNEL_ID.Organic,
            webConfigResp,
            isMobile
          );
        }
    });
  };

  useEffect(() => {
    const listener = async () => {
      const webConfigResp = await getWebConfig();
      if (webConfigResp.REACT_APP_BASE_URL)
        localStorage.setItem(
          "REACT_APP_BASE_URL",
          webConfigResp.REACT_APP_BASE_URL
        );
      const app = initializeApp(webConfigResp.firebaseConfig);
      const auth = getAuth(app);
      setAuthF(auth);
      if (!config) {
        const checkCountries = localStorage.getItem("country_priority");
        if (!checkCountries) {
          const countries = await getByName("CountryPriority");
          if (countries)
            localStorage.setItem(
              "country_priority",
              JSON.stringify(countries.value)
            );
        }
        setSearchContent({
          ...searchContent,
          app,
          auth,
          getAuthTest,
          userIp: await getIp(),
        });

        setConfig(true);
        getAuthTest(auth);
        return;
      }
      if (accessRoles.length === 0) {
        getAuthTest(auth);
        return;
      }
      setLoading(false);
    };
    listener();
    return () => {
      listener();
    };
  }, []);

  useEffect(() => {
    const headAttach = async () => {
      const script = document.createElement("script");
      const scriptGTM = document.createElement("script");
      const tophostname = top?.location.hostname.toString();
      if (tophostname === "app.dealpeak.com") {
        script.innerHTML = `window._mfq = window._mfq || [];
        (function() {
          var mf = document.createElement("script");
          mf.type = "text/javascript"; mf.defer = true;
          mf.src = "//cdn.mouseflow.com/projects/9328fd65-a93c-470a-a925-30c55e9f5ba9.js";
          document.getElementsByTagName("head")[0].appendChild(mf);
        })();`;
        document.body.appendChild(script);

        scriptGTM.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P84HP2D');`;

        document.head.appendChild(scriptGTM);
      }
    };
    headAttach();
    if (window.location.href.includes("app.dealpeak")) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        window.__lo_site_id = 'db3fa0a9';
        (function() {
          var wa = document.createElement('script');
          wa.type = 'text/javascript';
          wa.async = true;
          wa.src = 'https://d20519brkbo4nz.cloudfront.net/core/lo.js';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wa, s);
        })();
      `;

      // Append the script element to the head
      document.head.appendChild(script);

      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    let clickTripzScript: Node;
    let gtagManager: Node;
    const isProd =
      top?.location.hostname.toString().indexOf("app.dealpeak") !== -1;

    if (isProd) {
      clickTripzScript = addClickTripz();
      gtagManager = addGtag739();
      const metaTag = document.createElement("meta");
      metaTag.setAttribute("name", "fo-verify");
      metaTag.setAttribute("content", "d02ef98c-0fee-4c37-badf-2013f17b24a6");
      document.head.appendChild(metaTag);
    }
    return () => {
      if (isProd) {
        removeClickTripz(clickTripzScript);
      }
    };
  }, []);

  if (isNoIframeOrIframeInMyHost() === true) {
    return (
      <ViewportProvider>
        <SearchContext.Provider value={{ searchContent, setSearchContent }}>
          <WebConfigContext.Provider
            value={{ webConfigContext, setWebConfigContext }}
          >
            <PriceContext.Provider value={{ priceContext, setPriceContext }}>
              <React.Suspense
                fallback={<LoaderComponent title="Loading Site" />}
              >
                <EmailUdpateModal
                  setVisible={setModalVisible}
                  visible={modalVisible}
                />
                {authF && (
                  <MainHeader
                    className="main-header"
                    accessRole={accessRoles}
                    auth={authF}
                    setAccesRole={setAccessRoles}
                  />
                )}
                <Layout className="main-layout" style={{ minHeight: height }}>
                  <SearchBoxFilter
                    onClickOpenMenu={handlerFilterClick}
                    isVisible={isSearchBoxVisible}
                  />
                  <Content style={{ paddingBottom: "64px" }}>
                    {loading ? (
                      <LoaderComponent title="Loading..." />
                    ) : (
                      <RouterComponent
                        openMenu={menuOpen}
                        loading={loading}
                        accessRoles={accessRoles}
                        setAccesRole={setAccessRoles}
                        setIsSearchBoxVisible={setisSearchBoxVisible}
                        auth={authF}
                      />
                    )}
                    <BackTop className="mb-5 back-top-button">
                      <Button
                        type="primary"
                        className="rounded-pill 1 "
                        icon={
                          <FontAwesomeIcon
                            icon={faAngleDoubleUp as IconProp}
                            className="me-3"
                          />
                        }
                      >
                        Back top
                      </Button>
                    </BackTop>
                  </Content>
                  <Footer
                    className="bg-color-azul-oscuro shadow-lg text-white"
                    style={{
                      position: "fixed",
                      bottom: 0,
                      width: "100%",
                      zIndex: "999",
                    }}
                  >
                    <FooterCopyright />
                  </Footer>
                </Layout>
              </React.Suspense>
            </PriceContext.Provider>
          </WebConfigContext.Provider>
        </SearchContext.Provider>
      </ViewportProvider>
    );
  }
  return (
    <SearchContext.Provider value={{ searchContent, setSearchContent }}>
      <React.Suspense fallback={<LoaderComponent title="Loading Site" />}>
        <SearchBoxWidget />
      </React.Suspense>
    </SearchContext.Provider>
  );
};

export default Sentry.withProfiler(App);
